import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/values.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Blueprint from "../images/blueprint.png"
import SecurityBlueprint from "../images/zero-trust.png"
import MobileBlueprint from "../images/mobileblueprint.png"
import ImageMap from "image-map"
import "../styles/charity.scss"
import { FaChevronDown } from "react-icons/fa"
// import BackgroundImage from "gatsby-background-image"
import Bluesvg from "../components/bluprnt"
import blueprintsvg from "../images/blueprint.svg"

import r1c1 from "../images/png-bp/vis-ins.png"
import r1c2 from "../images/png-bp/aut-int.png"
import r1c3 from "../images/png-bp/app-ser.png"

import usersecurity from "../images/Identity_Security.svg"
import datacloudsecurity from "../images/Data_Cloud_Security.svg"
import cybersecurity from "../images/Cybersecurity.svg"
import devsecopssecurity from "../images/Devsecops.svg"

import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"

const SecurityShield = () => {
  useEffect(() => {
    ImageMap("img[usemap]")
  })

  const [showVisibility, setShowVisibility] = useState(false)
  const handleVisibilityClick = () => {
    setShowVisibility(!showVisibility)
  }

  const [showAutomation, setShowAutomation] = useState(false)
  const handleAutomationClick = () => {
    setShowAutomation(!showAutomation)
  }

  const [showApplication, setShowApplication] = useState(false)
  const handleApplicationClick = () => {
    setShowApplication(!showApplication)
  }
  return (
    <>
      <div
        className="outcomes-section"
        id="blueprint"
        style={{
          paddingTop: "0",
          marginTop: "0",
        }}
      >
        <div
          className="section"
          style={{
            paddingTop: "0",
            marginTop: "0",
          }}
        >
          <div
            className="outer-container"
            style={{
              paddingTop: "0",
              marginTop: "0",
            }}
          >
            {/* ///////////////////////       SVG        /////////////////////////// */}

            {/* <Bluesvg/> */}
            <img
              className="desktop-c"
              src={SecurityBlueprint}
              alt="security-blueprint"
              style={{
                width: "60%",
                paddingBottom: "20px",
              }}
            />
            {/* /////////////////   MOBILE BLUEPRINT   //////////////////////// */}
            {/* <img
              className="mobile-b"
              src={SecurityBlueprint}
              alt="blueprint"
              useMap="#image-map2"
            /> */}
            <p>
              Our Zero Trust Security Blueprint is closely modelled on, and
              integrated with, our Digital Maturity Blueprint. This blueprint
              provides us with the ability to automate security at scale while
              innovating with speed and safety. Metsi makes it easier for
              customers to define, deliver, manage, and consume secure services,
              by collecting, analysing, and accurately correlating data across
              an organisation's On-premise, Cloud and Micro-Services
              environments.
            </p>
            <p>
              <strong> Identity Security</strong> encompasses Cloud Access,
              Identity Governance, Authentication and Privileged Management.
            </p>
            <p>
              <strong> Data & Cloud Security</strong> houses Encryption, Data
              Governance, Secure Access Service Edge and Cloud Access Security
              Broker.{" "}
            </p>
            <p>
              <strong> Cyber Security </strong> provides Real Time Monitoring and
              Data Threat Intelligence, allowing you to Orchestrate and Respond.
            </p>
            <p>
              <strong> DevSecOps </strong> allows for API Management, Container
              Security and Security as Code.
            </p>
          </div>
        </div>
      </div>
      {/* ******************* Full alternative ************************ */}
      <div className="values-full" id="securityblueprint">
        {/* ////// Identity SECURITY ////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">Identity Security</h1>
                  <h2>
                    Protect your users' identity, access and authorisation.
                  </h2>
                  {/* <p>
                    A strong User Governance framework is the thread which
                    ensures that the right Users have access to the right
                    Services while providing business advantage through
                    self-enrolment, user life-cycle and a quality customer
                    experience. Identity management is a foundational security
                    component to help ensure users have the access they need,
                    and that systems, data, and applications are inaccessible to
                    unauthorised users.
                  </p> */}

                  <div className="v-row explainer">
                    <div className="v-col-1">
                      <div className="icon-border">
                        <img
                          onClick={handleVisibilityClick}
                          src={usersecurity}
                          width="150px"
                          height="150px"
                          className="accordion-open"
                        ></img>
                      </div>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        A strong User Governance framework ensures that the
                        right Users have access to the right Services while
                        providing business advantage through self-enrolment,
                        user life-cycle and a quality customer experience.
                        Identity management is a foundational security component
                        to help ensure users have the access they need, and that
                        systems, data, and applications are inaccessible to
                        unauthorised users.
                      </strong>

                      <ButtonB onClick={handleVisibilityClick}>
                        {showVisibility ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showVisibility
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Cloud Access
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Enterprises adopting cloud-based services can leverage
                        federated single sign-on for secure information sharing
                        across private, public and hybrid cloud deployments.
                        Implement a powerful identity mediation service for
                        Cloud, SaaS and Web services.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          User Governance
                        </h3>
                      </div>
                      <div className="v-col-2">
                        User Governance offers enhanced visibility. Know exactly
                        who has access to which information resources within an
                        organisation. Governance implies that the control of
                        access is driven by policy as well as procedure. Access
                        Governance systems are important to enterprises due to
                        an increased emphasis on regulatory compliance and
                        heightened sensitivity to insider threat. IT
                        organisations can reduce access management complexity
                        and increase operational efficiency while minimising
                        risk and ensuring compliance.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          Authentication
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Authentication solutions securely manage access to
                        business-critical applications and data. These solutions
                        provide centralised authentication and authorisation,
                        policy management and access control services for web
                        resources, systems and hosted applications. Single
                        Sign-On (SSO) improves the user experience and reduces
                        help-desk costs. Centralise the management of two-factor
                        authentication functions, security tokens and user
                        across the enterprise.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex", animationDelay: "0.45s" }
                              : { display: "none" }
                          }
                        >
                          Privileged Management
                        </h3>
                      </div>
                      <div className="v-col-2">
                        A privileged account is typically the administrator,
                        super-user or root account for a given system.
                        Privileged accounts provide the capability not only to
                        reconfigure and/or apply modifications to a system and
                        its components, but also to access any information that
                        is held on the system. Security and management of these
                        privileged accounts is a priority for all enterprises.
                        When users require use of a privileged account, the
                        “Username” and “Password” is booked-out from the
                        automated self-service Password Safe.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //////////// Data & Cloud Security /////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">Data & Cloud Security</h1>
                  <h2>
                    Identify the value of data and provide an enriched data
                    governance lifecycle.
                  </h2>
                  {/* <p>
                    Brokering the capabilities of your multi-cloud platforms
                    (the “what you have”) with the demands of your consumers
                    (the services) – turning your capabilities into customer
                    value. Doing it effectively, efficiently, and in a manner
                    fit for your business.
                  </p> */}
                  <div className="v-row">
                    <div className="v-col-1">
                      <div className="icon-border">
                        <img
                          src={datacloudsecurity}
                          width="150px"
                          height="150px"
                          className="accordion-open"
                          onClick={handleAutomationClick}
                        ></img>
                      </div>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Only by identifying the business value of data can
                        organisations make intelligent, deliberate decisions on
                        how information is managed, consumed and analysed. This
                        forms the basis of an effective IT governance strategy.
                        Once the value of the data is identified, security
                        measures can be implemented to stop the un-authorised
                        access and disclosure of information.
                      </strong>

                      <ButtonB onClick={handleAutomationClick}>
                        {showAutomation ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showAutomation
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Encryption
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Encryption solutions ensure the security, privacy and
                        integrity of data. These solutions also provide the
                        ability to mask sensitive information from prying eyes,
                        allowing for a “need to know” approach to sensitive data
                        in administrative repositories.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          Data Governance
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Metsi Technologies has a comprehensive Data Governance
                        methodology to assist organisations in adopting and
                        deploying these solutions to address requirements
                        related to data security and governance, driven by
                        universal Data Governance policies.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          Secure Access Service Edge
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Secure Access Service Edge (SASE) in the cloud
                        integrates networking and security capabilities to
                        provide seamless, secure access to apps wherever users
                        operate.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex", animationDelay: "0.45s" }
                              : { display: "none" }
                          }
                        >
                          Cloud Access Security Broker
                        </h3>
                      </div>
                      <div className="v-col-2">
                        CASB technologies intend to extend the traditional
                        on-premises data protection capabilities like Data Loss
                        Prevention (DLP) into cloud platforms. CASB technologies
                        provide full visibility into the use of “Shadow IT” and
                        provide the necessary controls to ensure that only
                        allowed platforms are used and that sensitive data is
                        not exposed/leaked via these platforms.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /////////// Cyber security /////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">Cyber Security</h1>
                  <h2>
                    Provide integrated and actionable intelligence and
                    remediation policies for threat incidents.
                  </h2>
                  {/* <p>
                    Managing the multi-cloud platform services portfolio on
                    behalf of the business. Delivering on the demands of
                    multi-cloud service consumers – delivering capability,
                    providing customer value, and evolving when and as fast as
                    your customers need.
                  </p> */}
                  <div className="v-row">
                    <div className="v-col-1">
                      <div className="icon-border">
                        <img
                          src={cybersecurity}
                          width="150px"
                          height="150px"
                          className="accordion-open"
                          onClick={handleApplicationClick}
                        ></img>
                      </div>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        A Collaborative and Integrated Cyber Security framework
                        provides pervasive infrastructure visibility. Obtain
                        situational awareness of the content of all network
                        traffic and discrete behaviour of entities operating
                        across the network, while enabling actionable
                        intelligence and immediate threat investigations.
                        Metsi’s cyber security approach allows clients the
                        ability to provide forensic analysis on massive archives
                        for incident investigation, scalability and powerful
                        analytics. Automate processes, reduce incident time, and
                        adapt to changing threats, while initiating automated
                        security incident remediation.
                      </strong>

                      <ButtonB onClick={handleApplicationClick}>
                        {showApplication ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showApplication
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Real Time Monitoring
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Our solution is the industry’s most advanced, scalable
                        and extensible for continuous vulnerability management
                        and compliance. As a cloud-based service, it provides
                        immediate, global visibility into where IT systems may
                        be vulnerable to the latest Internet threats and how to
                        protect them. It helps you to continuously identify
                        threats and monitor unexpected changes across a network
                        before they grow into breaches.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          Data Threat Intelligence
                        </h3>
                      </div>
                      <div className="v-col-2">
                        In this digital age businesses are continuously at risk
                        from cyber threats and the adverse effects of business
                        interruption. These risks have increased dramatically in
                        recent years as threats develop and become more
                        advanced. Detecting novel attacks and insider threats at
                        an early stage is no easy task. Business requires a
                        solution that can spot the subtle signals of an advanced
                        attack — without relying on rules or signatures. We
                        provide a solution that uses unsupervised machine
                        learning to distinguish the good from the bad on your
                        network; from users and devices to your cloud
                        infrastructure and everything in between. By monitoring
                        the entire environment, organisations benefit from a
                        unified view of their entire digital estate.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          Orchestrate and Respond
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Intelligence technology has fundamentally changed the
                        way cyberattacks are detected by focusing on attacker
                        behaviour rather than ever-changing malware and tools.
                        With the rapid adoption of cloud-based services it is
                        essential that your SIEM platform can ingest the audit
                        data from these platforms, allowing business to
                        orchestrate and respond against attacks on these
                        platforms.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /////////// DevSecOps /////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">DevSecOps</h1>
                  <h2>Embed a culture of security in the DevOps lifecycle.</h2>
                  {/* <p>
                    Managing the multi-cloud platform services portfolio on
                    behalf of the business. Delivering on the demands of
                    multi-cloud service consumers – delivering capability,
                    providing customer value, and evolving when and as fast as
                    your customers need.
                  </p> */}
                  <div className="v-row">
                    <div className="v-col-1">
                      <div className="icon-border">
                        <img
                          src={devsecopssecurity}
                          width="150px"
                          height="150px"
                          className="accordion-open"
                          onClick={handleApplicationClick}
                        ></img>
                      </div>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        DevSecOps builds on the methodology of DevOps. The
                        crucial idea is to include security as early
                        (Shift-left) on in the Software Development Lifecycle
                        (SDLC) process as possible and ensure that it is
                        embedded in every stage of this lifecycle. Including
                        security from the very beginning ensures that everyone
                        involved is responsible, the cost of compliance is
                        reduced and secure code is released much faster.
                      </strong>

                      <ButtonB onClick={handleApplicationClick}>
                        {showApplication ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showApplication
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Security as Code
                        </h3>
                      </div>
                      <div className="v-col-2">
                        In the fast-paced world of software development,
                        business needs to deliver feature rich applications at
                        speeds that are not possible without automation. It is
                        vitally important that you introduce mechanisms that
                        support the automated scanning of code to ensure that
                        security testing is not left until the end.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          Container Security
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Container platforms need to be monitored, whether they
                        are on-prem or in the cloud. This continuous monitoring
                        ensures that vulnerabilities and security
                        misconfigurations are not introduced at any stage and
                        that the containers continue to function as intended.
                        Your chosen platform must be able to dynamically scan
                        for advanced threats or malware in your container images
                        as well as alert you about misconfigurations in public
                        cloud environments.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          API Management
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Credentials, keys, APIs, tokens and other secrets used
                        in DevOps environments are a prime target for attackers.
                        Using a DevOps tools centred approach to managing
                        secrets contributes to secrets sprawl and expands the
                        attack surface. Implementing a centralised
                        administration solution, built for continuous
                        development environments with security as the driving
                        factor, reduces the risk of exposure without slowing
                        down the application delivery process.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SecurityShield
