import qs from "query-string"

const setQueryStringWithoutPageReload = qsValue => {
  if (typeof window !== "undefined") {
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      qsValue
    window.history.pushState({ path: newurl }, "", newurl)
  }
}

const getQueryStringValue = (key, queryString) => {
  if (typeof window !== "undefined") {
    queryString = window.location.search
    const values = qs.parse(queryString)
    return values[key]
  }
}

const setQueryStringValue = (key, value, queryString) => {
  if (typeof window !== "undefined") {
    queryString = window.location.search
    const values = qs.parse(queryString)
    const newQsValue = qs.stringify({
      ...values,
      [key]: value,
    })
    setQueryStringWithoutPageReload(`?${newQsValue}`)
  }
}

export { getQueryStringValue, setQueryStringValue }
