import React from "react"
import "./ButtonFancy.scss"

const ButtonFancy = ( {children} ) => {
    return(
  
    <div class="button_base b08_3d_pushback2" id="btn1">
      <div>
        <a class="btn-shine2">{children}</a>
      </div>
      <div>
        <a class="btn-shine">{children}</a>
      </div>
    </div>
  )
}

export default ButtonFancy
