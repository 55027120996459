import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/values.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Blueprint from "../images/blueprint.png"
import MobileBlueprint from "../images/mobileblueprint.png"
import ImageMap from "image-map"
import "../styles/charity.scss"
import { FaChevronDown } from "react-icons/fa"
// import BackgroundImage from "gatsby-background-image"
import Bluesvg from "../components/bluprnt"
import blueprintsvg from "../images/blueprint.svg"

import DigitalMaturityBlueprint from "../components/DigitalMaturityBlueprint"

import r1c1 from "../images/png-bp/vis-ins.png"
import r2c1 from "../images/png-bp/r2c1.png"
import r3c1 from "../images/png-bp/r3c1.png"
import r4c1 from "../images/png-bp/r3c1.png"
import r1c2 from "../images/png-bp/aut-int.png"
import r2c2 from "../images/png-bp/r2c2.png"
import r3c2 from "../images/png-bp/r3c2.png"
import r4c2 from "../images/png-bp/r3c2.png"
import r1c3 from "../images/png-bp/app-ser.png"
import r2c3 from "../images/png-bp/r2c3.png"
import r3c3 from "../images/png-bp/r3c3.png"
import r4c3 from "../images/png-bp/r3c3.png"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import SecurityShield from "../components/SecurityShield"
import ButtonFancy from "../components/FancyButtons/ButtonFancy"

import useQueryString from "../components/useQueryString";

const Values = () => {
  const [showSecurity, setShowSecurity] = useState(false)

  const [value, onSetValue] = useQueryString("blueprint")

  const buttonProps = {
    backgroundColor: !showSecurity ? '#005b85' : 'white',
    color: !showSecurity ? 'white':'#005b85' ,
    backgroundColor2: showSecurity ? '#005b85' : 'white',
    color2: showSecurity ? 'white':'#005b85' ,
  }

  // useEffect(() => {

  //   if(showSecurity){
  //     onSetValue("zero-trust")
  //   }else{
  //     onSetValue("digital-maturity")
  //   }


  // })

  useEffect(() => {
    
    if(value==="zero-trust"){
      setShowSecurity(true)
    }
    else{
      setShowSecurity(false)
    }
  })

  return (
    <Layout>
      <SEO
        title="Values"
        description="Metsi accelerates the customer journey to digital maturity, with a service-centric approach to digital strategy transformation and Zero Trust Security. We build intelligent IT infrastructure to drive business agility and service innovation."
        keywords={["digital maturity", "zero trust security", "application and platform modernisation", "cyber security management"]}
        lang="en-gb"
      />
      <div className="values-bkg">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Metsi Value Proposition and Blueprint</h1>
                  <h2>Outcomes for our customers</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="outcomes-section">
        <div className="section">
          <div className="outer-container">
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1">
              <h2>Value Proposition</h2>
              <h1>The Blueprint Behind Our Business</h1>

              <div className="inner-container" style={{ paddingTop: "0" }}>
                <p>
                  Metsi enables customers to
                  <strong> accelerate </strong>
                  their journey to <strong> digital maturity </strong> by
                  aligning to their IT and business goals.
                </p>
                <p>
                We do this by taking a
                  <strong> service-centric approach </strong>
                  to <strong>
                    {" "}
                    application and platform modernisation,{" "}
                  </strong>{" "}
                  making it simpler for customers to{" "}
                  <strong> define, deliver, manage </strong> and consume{" "}
                  <strong> secure </strong> services, aligned to their IT and
                  business goals.
                </p>

                <p>
                  Our expert teams build intelligent solutions based on
                  data-driven insights, to provide outcomes that drive
                  <strong> business agility </strong>
                  and <strong> service innovation. </strong>
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="blueprint-toggle">
          <div>
          <a onClick={() => onSetValue("digital-maturity")}>
            <ButtonFancy style={{
              backgroundColor: buttonProps.backgroundColor,
              color: buttonProps.color
            }}>
              Digital Maturity
            </ButtonFancy>
          </a></div>
          <div>
          <a onClick={() => onSetValue("zero-trust")}  style={{backgroundColor: "transparent", margin: '0', padding: '0', border: '0'}}>
            <ButtonFancy
             style={{
              backgroundColor: buttonProps.backgroundColor2,
              color: buttonProps.color2
            }}>
              Zero Trust Security
            </ButtonFancy>
          </a></div>
        </div>
      </div>
            <a id='blueprint' style={{marginTop:'-100px'}}></a>
      {!showSecurity ? <DigitalMaturityBlueprint /> : <SecurityShield />}
    </Layout>
  )
}

export default Values
