import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/values.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Blueprint from "../images/blueprint.png"
import MobileBlueprint from "../images/mobileblueprint.png"
import ImageMap from "image-map"
import "../styles/charity.scss"
import { FaChevronDown } from "react-icons/fa"
// import BackgroundImage from "gatsby-background-image"
import Bluesvg from "../components/bluprnt"
import blueprintsvg from "../images/blueprint.svg"

import r1c1 from "../images/png-bp/vis-ins.png"
import r1c2 from "../images/png-bp/aut-int.png"
import r1c3 from "../images/png-bp/app-ser.png"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"

const DigitalMaturityBlueprint = () => {
    useEffect(() => {
        ImageMap("img[usemap]")
      })
    
      const [showVisibility, setShowVisibility] = useState(false)
      const handleVisibilityClick = () => {
        setShowVisibility(!showVisibility)
      }
    
      const [showAutomation, setShowAutomation] = useState(false)
      const handleAutomationClick = () => {
        setShowAutomation(!showAutomation)
      }
    
      const [showApplication, setShowApplication] = useState(false)
      const handleApplicationClick = () => {
        setShowApplication(!showApplication)
      }
    return(
        <>
        <div className="outcomes-section" id="blueprint" 
        style={{
            paddingTop: '0',
            marginTop: '0',
        }}>
        <div className="section" style={{
            paddingTop: '0',
            marginTop: '0'
        }}>
          <div className="outer-container" style={{
            paddingTop: '0',
            marginTop: '0'
        }}>

            {/* ///////////////////////       SVG        /////////////////////////// */}

            {/* <Bluesvg/> */}
            <img className="desktop-b" src={Blueprint} alt="blueprint" />

            {/* /////////////////   MOBILE BLUEPRINT   //////////////////////// */}
            <img
              className="mobile-b"
              src={MobileBlueprint}
              alt="blueprint"
              useMap="#image-map2"
              style={{marginBottom:"40px"}}
            />
            <map name="image-map2">
              <area
                target=""
                alt="Visibility and insights"
                title="Visibility and Insights"
                href="/visibility-and-insights"
                coords="93,187,190,186,203,166,208,142,205,117,191,97,171,80,144,73,126,74,103,85,85,102,75,126,75,154,81,173"
                shape="poly"
              />
              <area
                target=""
                alt="Automation and intelligence"
                title="Automation and Intelligence"
                href="/automation-and-intelligence"
                coords="95,527,192,526,205,506,210,482,207,457,193,437,173,420,146,413,128,414,105,425,87,442,77,466,77,494,83,513"
                shape="poly"
              />
              <area
                target=""
                alt="Application Managements"
                title="Application Managements"
                href="/application-lifecycle"
                coords="90,868,187,867,200,847,205,823,202,798,188,778,168,761,141,754,123,755,100,766,82,783,72,807,72,835,78,854"
                shape="poly"
              />

              <area
                target=""
                alt="Business insights"
                title="Business insights"
                href="/visibility-and-insights#discovery"
                coords="67,191,218,190,232,196,241,210,242,227,233,239,218,247,66,245"
                shape="poly"
              />
              <area
                target=""
                alt="Analytics"
                title="Analytics"
                href="/visibility-and-insights#discovery"
                coords="67,252,218,251,232,257,241,271,242,288,233,300,218,308,66,306"
                shape="poly"
              />
              <area
                target=""
                alt="Discovery"
                title="Discovery"
                href="/visibility-and-insights#discovery"
                coords="67,315,218,314,232,320,241,334,242,351,233,363,218,371,66,369"
                shape="poly"
              />

              <area
                target=""
                alt="Service optimisation"
                title="Service optimisation"
                href="/automation-and-intelligence#orchestration"
                coords="66,531,227,530,241,536,250,550,251,567,242,579,227,587,66,586"
                shape="poly"
              />
              <area
                target=""
                alt="Service orchestration"
                title="Service orchestration"
                href="/automation-and-intelligence#orchestration"
                coords="66,593,227,592,241,598,250,612,251,629,242,641,227,649,66,648"
                shape="poly"
              />
              <area
                target=""
                alt="Software defined infrastructure"
                title="Software defined infrastructure"
                href="/automation-and-intelligence#software"
                coords="66,655,227,654,241,660,250,674,251,691,242,703,227,711,66,710"
                shape="poly"
              />

              <area
                target=""
                alt="Service catalog"
                title="Service catalog"
                href="/application-lifecycle#fullstack"
                coords="66,870,220,869,234,875,243,889,244,906,235,918,220,926,66,926"
                shape="poly"
              />
              <area
                target=""
                alt="Lifecycle"
                title="Lifecycle"
                href="/application-lifecycle#application-modernisation"
                coords="65,933,219,932,233,938,242,952,243,969,234,981,219,989,65,989"
                shape="poly"
              />
              <area
                target=""
                alt="Modelling"
                title="Modelling"
                href="/application-lifecycle#fullstack"
                coords="66,994,220,993,234,999,243,1013,244,1030,235,1042,220,1050,66,1050"
                shape="poly"
              />

              <area
                target=""
                alt="Network Infrastructure"
                title="Network Infrastructure"
                href="/automation-and-intelligence#optimisation"
                coords="48,1192,139,1280"
                shape="rect"
              />
              <area
                target=""
                alt="Containers"
                title="Containers"
                href="/automation-and-intelligence#optimisation"
                coords="168,1192,257,1269"
                shape="rect"
              />
              <area
                target=""
                alt="Public Cloud"
                title="Public Cloud"
                href="/automation-and-intelligence#optimisation"
                coords="49,1285,138,1362"
                shape="rect"
              />
              <area
                target=""
                alt="Private Cloud"
                title="Private Cloud"
                href="/automation-and-intelligence#optimisation"
                coords="167,1288,256,1365"
                shape="rect"
              />
              <area
                target=""
                alt="Hybrid Cloud"
                title="Hybrid Cloud"
                href="/automation-and-intelligence#optimisation"
                coords="49,1374,138,1451"
                shape="rect"
              />
              <area
                target=""
                alt="Multi-cloud"
                title="Multi-cloud"
                href="/automation-and-intelligence#software"
                coords="171,1372,260,1449"
                shape="rect"
              />
            </map>

            <p>
              The Metsi Blueprint ensures that strategies for transformation and
              digital maturity are comprehensive and aligned with the specific
              organisational goals.
            </p>
            <p>
              <strong> Visibility & Insights</strong> empower effective decision
              making.
            </p>
            <p>
              <strong> Automation & Intelligence</strong> ensures successful
              execution and operations.{" "}
            </p>
            <p>
              <strong> Application & Service Management</strong> delivers
              capabilities that provide customer value and evolve as your
              customers and business require.
            </p>

            {/* /////////////////   DESKTOP BLUEPRINT   //////////////////////// */}

            {/* <img className="desktop-b" src={Blueprint} alt="blueprint" useMap="#image-map"/>
          <map name="image-map">
          <area target="" alt="Visibility and insights" title="Visibility and Insights" href="/visibility-and-insights" coords="301,435,522,435,545,407,562,351,561,308,549,263,513,208,462,183,412,173,363,179,322,198,292,223,271,258,256,298,255,338,261,379,276,404" shape="poly"/>
          <area target="" alt="Automation and intelligence" title="Automation and Intelligence" href="/automation-and-intelligence" coords="818,433,1039,433,1062,405,1079,349,1078,306,1066,261,1030,206,979,181,929,171,880,177,839,196,809,221,788,256,773,296,772,336,778,377,793,402" shape="poly"/>
          <area target="" alt="Application Managements" title="Application Managements" href="/application-lifecycle" coords="1342,437,1563,437,1586,409,1603,353,1602,310,1590,265,1554,210,1503,185,1453,175,1404,181,1363,200,1333,225,1312,260,1297,300,1296,340,1302,381,1317,406" shape="poly"/>
         
          <area target="" alt="Business insights" title="Business insights" href="/visibility-and-insights#discovery" coords="235,446,233,550,235,561,254,570,588,568,603,560,623,546,635,529,640,504,633,475,620,455,605,444,588,438,252,436,240,438" shape="poly"/>
          <area target="" alt="Analytics" title="Analytics" href="/visibility-and-insights#discovery" coords="235,588,233,692,235,703,254,712,588,710,603,702,623,688,635,671,640,646,633,617,620,597,605,586,588,580,252,578,240,580" shape="poly"/>
          <area target="" alt="Discovery" title="Discovery" href="/visibility-and-insights#discovery" coords="235,730,233,834,235,845,254,854,588,852,603,844,623,830,635,813,640,788,633,759,620,739,605,728,588,722,252,720,240,722" shape="poly"/>
          
          <area target="" alt="Service optimisation" title="Service optimisation" href="/automation-and-intelligence#orchestration" coords="745,447,745,552,749,560,757,570,1122,569,1137,561,1157,547,1169,530,1174,505,1167,476,1154,456,1139,445,1122,439,765,438,749,438" shape="poly"/>
          <area target="" alt="Service orchestration" title="Service orchestration" href="/automation-and-intelligence#orchestration" coords="745,587,745,692,749,700,757,710,1122,709,1137,701,1157,687,1169,670,1174,645,1167,616,1154,596,1139,585,1122,579,765,578,749,578" shape="poly"/>
          <area target="" alt="Software defined infrastructure" title="Software defined infrastructure" href="/automation-and-intelligence#software" coords="745,729,745,834,749,842,757,852,1122,851,1137,843,1157,829,1169,812,1174,787,1167,758,1154,738,1139,727,1122,721,765,720,749,720" shape="poly"/>
          
          <area target="" alt="Service catalog" title="Service catalog" href="/application-lifecycle#fullstack" coords="1282,455,1282,546,1284,564,1301,570,1641,567,1656,559,1676,545,1688,528,1693,503,1686,474,1673,454,1658,443,1641,437,1309,438,1290,441" shape="poly"/>
          <area target="" alt="Lifecycle" title="Lifecycle" href="/application-lifecycle#application-modernisation" coords="1280,597,1280,688,1282,706,1299,712,1639,709,1654,701,1674,687,1686,670,1691,645,1684,616,1671,596,1656,585,1639,579,1307,580,1288,583" shape="poly"/>
          <area target="" alt="Modelling" title="Modelling" href="/application-lifecycle#fullstack" coords="1282,739,1282,830,1284,848,1301,854,1641,851,1656,843,1676,829,1688,812,1693,787,1686,758,1673,738,1658,727,1641,721,1309,722,1290,725" shape="poly"/>
          
          
          <area target="" alt="Network Infrastructure" title="Network Infrastructure" href="/automation-and-intelligence#optimisation" coords="147,1074,345,1272" shape="rect"/>
          <area target="" alt="Containers" title="Containers" href="/automation-and-intelligence#optimisation" coords="421,1076,611,1265" shape="rect"/>
          <area target="" alt="Public Cloud" title="Public Cloud" href="/automation-and-intelligence#optimisation" coords="691,1076,880,1261" shape="rect"/>
          <area target="" alt="Private Cloud" title="Private Cloud" href="/automation-and-intelligence#optimisation" coords="977,1076,1183,1261" shape="rect"/>
          <area target="" alt="Hybrid Cloud" title="Hybrid Cloud" href="/automation-and-intelligence#optimisation" coords="1274,1071,1484,1253" shape="rect"/>
          <area target="" alt="Multi-cloud" title="Multi-cloud" href="/automation-and-intelligence#software" coords="1561,1073,1760,1255" shape="rect"/>          
         </map> */}

            
          </div>
        </div>
      </div>
      {/* ******************* Full alternative ************************ */}
      <div className="values-full">
        {/* ////// Visibility & Insights ////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">Visibility & Insights</h1>
                  <h2>
                    The "what you have & do" - knowledge is power. The
                    foundation for all your decision making.
                  </h2>
                  <p>
                    From infrastructure (physical, virtual & software), to the
                    platform services that are consumed – understand their
                    existence, state, design and behaviour. Ultimately aligning
                    these services to the value they bring the business.
                  </p>

                  <div className="v-row explainer">
                    <div className="v-col-1">
                      <img
                        onClick={handleVisibilityClick}
                        src={r1c1}
                        width="200px"
                        height="200px"
                        className="accordion-open"
                      ></img>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Be empowered to make better business decisions founded
                        on a clear understanding of your customer and enterprise
                        digital experience. Through end-to-end service
                        visibility you can measure and monitor digital
                        experience, improve service availability, drive
                        automation based on data-driven insights, and more
                        easily assess business impact and opportunity from you
                        multi-cloud platforms.
                      </strong>

                      <ButtonB onClick={handleVisibilityClick}>
                        {showVisibility ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showVisibility
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Business Insights
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Is what I have and do appropriate for the business and
                        our risk? Could my business do more, do better, or do
                        something different (innovate) with what I have and do?
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          Analytics
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Is it correct and complete? – Is what I have what I am
                        meant to have, and is it doing what it is meant to do?
                        What don’t I have that I need, what should I be doing
                        that I’m not?
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-visibility"
                          style={
                            showVisibility
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          Discovery
                        </h3>
                      </div>
                      <div className="v-col-2">
                        What do I have, what did it do, and when did it do it? –
                        Understand everything that you have and what state it is
                        in, how it moves and how it changes. All the components
                        and resources that are the ingredients to building
                        on-prem, hybrid and multi-cloud services. All the data
                        that resides in or traverses your network.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //////////// Automation & Intelligence /////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">Automation & Intelligence</h1>
                  <h2>
                    The “how you do it” – your ability to execute. This is good
                    operations{" "}
                  </h2>
                  <p>
                    Brokering the capabilities of your multi-cloud platforms
                    (the “what you have”) with the demands of your consumers
                    (the services) – turning your capabilities into customer
                    value. Doing it effectively, efficiently, and in a manner
                    fit for your business.
                  </p>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img
                        src={r1c2}
                        width="200px"
                        height="200px"
                        className="accordion-open"
                        onClick={handleAutomationClick}
                      ></img>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Achieve a level of digital maturity and build a
                        multi-cloud operating model that is fit for your
                        business, continuously driving operational efficiencies
                        and platform cost optimisation. Maximise your return on
                        investment from your private cloud platforms, and never
                        pay more than you need for your public cloud services.
                        <br />
                        <br />
                        Through end-to-end service orchestration built on a
                        principle of everything-as-a-service, our Service Broker
                        architecture enables the business to build
                        infrastructure abstractions that best fits your
                        organisation's alignment of technology, people and
                        processes. Build intelligent multi-cloud capabilities
                        that consume technologies based on business requirements
                        and not vendor features.
                      </strong>

                      <ButtonB onClick={handleAutomationClick}>
                        {showAutomation ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showAutomation
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Service Optimisation
                        </h3>
                      </div>
                      <div className="v-col-2">
                        How to I do it better? Better for the business, better
                        for our organisation, better for the customer. Build
                        intelligent services that are self-learning and
                        self-improving.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          Service Orchestration
                        </h3>
                      </div>
                      <div className="v-col-2">
                        The service broker engine for the business. Effectively
                        matching capability supply to service demand. Delivering
                        the right capability in the right place at the right
                        time, but also minimising the risk of changes in
                        services and demand to the impact on capability and
                        supply.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-automation"
                          style={
                            showAutomation
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          Software-Defined Infrastructure
                        </h3>
                      </div>
                      <div className="v-col-2">
                        How do I use what I have to do something a customer
                        wants? Turning your infrastructure and platform
                        components into software consumable service blocks.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /////////// Application & Service Management /////////// */}
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1 className="values-heading">
                    Application & Service Management
                  </h1>
                  <h2>
                    The “why you do it” – how you evolve & grow. This is good
                    business.
                  </h2>
                  <p>
                    Managing the multi-cloud platform services portfolio on
                    behalf of the business. Delivering on the demands of
                    multi-cloud service consumers – delivering capability,
                    providing customer value, and evolving when and as fast as
                    your customers need.
                  </p>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img
                        src={r1c3}
                        width="200px"
                        height="200px"
                        className="accordion-open"
                        onClick={handleApplicationClick}
                      ></img>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Deliver the services and digital experience that your
                        customers want, where and when they want it. Focus on,
                        optimise, and mature the services that are most
                        important to your business. Drive business agility
                        bringing new services to market faster, pivoting
                        existing services to meet changing market demands, and
                        quickly leverage new technologies to create service
                        innovation.
                        <br />
                        <br />
                        By extending the service-oriented architecture to
                        application modelling and software lifecycle management,
                        we enable businesses to effectively build and manage a
                        consumable and agile strategic services portfolio.
                      </strong>

                      <ButtonB onClick={handleApplicationClick}>
                        {showApplication ? "Less" : "More"}
                      </ButtonB>
                    </div>
                  </div>
                  <div
                    className="summary"
                    style={
                      showApplication
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          Software-Defined Infrastructure
                        </h3>
                      </div>
                      <div className="v-col-2">
                        What services do my consumers want that are smart for my
                        business to provide? Managing the multi-cloud services
                        portfolio – building the right services for your
                        customers, where and when they need it. Creating,
                        continuously improving and changing services rapidly in
                        line with changing business needs and customer demands –
                        creating business agility.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex", animationDelay: "0.15s" }
                              : { display: "none" }
                          }
                        >
                          Lifecycle
                        </h3>
                      </div>
                      <div className="v-col-2">
                        Continuously ensuring services are fit for purpose –
                        delivering on intent, quality and business value.
                        Managing the lifecycle of the service with the consumer
                        – delivering the service, maintaining, updating,
                        ensuring service levels, and elegantly retiring when
                        needed.
                      </div>
                    </div>
                    <div className="v-row">
                      <div className="v-col-1">
                        <h3
                          className="val-droplet-header val-droplet-header-application"
                          style={
                            showApplication
                              ? { display: "flex", animationDelay: "0.3s" }
                              : { display: "none" }
                          }
                        >
                          Modelling
                        </h3>
                      </div>
                      <div className="v-col-2">
                        What is it that the customer wants (from my multi-cloud
                        infrastructure and platforms)? Multi-cloud
                        infrastructure and platform service specifications,
                        cloud-agnostic application modelling – capturing service
                        intent.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //////////////////////////Visibility & Insights////////////////////////////
      {/*<div className="values-bkg4">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Visibility & Insights</h1>
                  <h2>
                    The "what you have & do" - knowledge is power. The
                    foundation for all your decision making.
                  </h2>
                  <p>
                    From infrastructure (physical, virtual & software), to the
                    platform services that are consumed – understand their
                    existence, state, design and behaviour. Ultimately aligning
                    these services to the value they bring the business.
                  </p>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r1c1} width="150px" height="150px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Be empowered to make better business decisions founded
                        on a clear understanding of your customer and enterprise
                        digital experience. Through end-to-end service
                        visibility you can measure and monitor digital
                        experience, improve service availability, drive
                        automation based on data-driven insights, and more
                        easily assess business impact and opportunity from you
                        multi-cloud platforms.{" "}
                      </strong>
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r2c1} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Business Insights:</strong> Is what I have and do
                      appropriate for the business and our risk? Could my
                      business do more, do better, or do something different
                      (innovate) with what I have and do?
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r3c1} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Analytics:</strong> Is it correct and complete? –
                      Is what I have what I am meant to have, and is it doing
                      what it is meant to do? What don’t I have that I need,
                      what should I be doing that I’m not?
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r4c1} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Discovery:</strong> What do I have, what did it
                      do, and when did it do it? – Understand everything that
                      you have and what state it is in, how it moves and how it
                      changes. All the components and resources that are the
                      ingredients to building on-prem, hybrid and multi-cloud
                      services. All the data that resides in or traverses your
                      network.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////////////////////Automation & Intelligence//////////////////////////// 
      <div className="values-bkg2">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Automation & Intelligence</h1>
                  <h2>
                    The “how you do it” – your ability to execute. This is good
                    operations{" "}
                  </h2>
                  <p>
                    Brokering the capabilities of your multi-cloud platforms
                    (the “what you have”) with the demands of your consumers
                    (the services) – turning your capabilities into customer
                    value. Doing it effectively, efficiently, and in a manner
                    fit for your business.
                  </p>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r1c2} width="150px" height="150px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Achieve a level of digital maturity and build a
                        multi-cloud operating model that is fit for your
                        business, continuously driving operational efficiencies
                        and platform cost optimisation. Maximise your return on
                        investment from your private cloud platforms, and never
                        pay more than you need for your public cloud services.
                        <br />
                        <br />
                        Through end-to-end service orchestration built on a
                        principle of everything-as-a-service, our Service Broker
                        architecture enables the business to build
                        infrastructure abstractions that best fits your
                        organisation's alignment of technology, people and
                        processes. Build intelligent multi-cloud capabilities
                        that consume technologies based on business requirements
                        and not vendor features.
                      </strong>
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r2c2} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Service Optimisation:</strong> How to I do it
                      better? Better for the business, better for our
                      organisation, better for the customer. Build intelligent
                      services that are self-learning and self-improving.
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r3c2} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Service Orchestration:</strong> The service broker
                      engine for the business. Effectively matching capability
                      supply to service demand. Delivering the right capability
                      in the right place at the right time, but also minimising
                      the risk of changes in services and demand to the impact
                      on capability and supply.
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r4c2} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Software-Defined Infrastructure:</strong> How do I
                      use what I have to do something a customer wants? Turning
                      your infrastructure and platform components into software
                      consumable service blocks.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //////////////////////////Application & Service Management//////////////////////////// 
      <div className="values-bkg3">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Application & Service Management</h1>
                  <h2>
                    The “why you do it” – how you evolve & grow. This is good
                    business.{" "}
                  </h2>
                  <p>
                    Managing the multi-cloud platform services portfolio on
                    behalf of the business. Delivering on the demands of
                    multi-cloud service consumers – delivering capability,
                    providing customer value, and evolving when and as fast as
                    your customers need.
                  </p>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r1c3} width="150px" height="150px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>
                        Deliver the services and digital experience that your
                        customers want, where and when they want it. Focus on,
                        optimise, and mature the services that are most
                        important to your business. Drive business agility
                        bringing new services to market faster, pivoting
                        existing services to meet changing market demands, and
                        quickly leverage new technologies to create service
                        innovation.
                        <br />
                        <br />
                        By extending the service-oriented architecture to
                        application modelling and software lifecycle management,
                        we enable businesses to effectively build and manage a
                        consumable and agile strategic services portfolio.
                      </strong>
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r2c3} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Service Catalog:</strong> What services do my
                      consumers want that are smart for my business to provide?
                      Managing the multi-cloud services portfolio – building the
                      right services for your customers, where and when they
                      need it. Creating, continuously improving and changing
                      services rapidly in line with changing business needs and
                      customer demands – creating business agility.
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r3c3} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Lifecycle:</strong> Continuously ensuring services
                      are fit for purpose – delivering on intent, quality and
                      business value. Managing the lifecycle of the service with
                      the consumer – delivering the service, maintaining,
                      updating, ensuring service levels, and elegantly retiring
                      when needed.
                    </div>
                  </div>
                  <div className="v-row">
                    <div className="v-col-1">
                      <img src={r4c3} width="150px" height="50px"></img>
                    </div>
                    <div className="v-col-2">
                      <strong>Modelling:</strong> What is it that the customer
                      wants (from my multi-cloud infrastructure and platforms)?
                      Multi-cloud infrastructure and platform service
                      specifications, cloud-agnostic application modelling –
                      capturing service intent.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      </>
    )
}

export default DigitalMaturityBlueprint